.userDetail
{
    margin-top: 40px;
    font-size: 23px;
}

.contact
{
    margin-top: 10px;
    font-size: 23px;
}
.location
{
    margin-top: 10px;
    font-size: 23px;
}
#servicesImg
{
    width: 45%; 
    margin: 25px;   
}
#serviceCard
{
    align-items: center;
    text-align: center;
    margin: 25px;
    background-color: #EDEDED;
}
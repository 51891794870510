nav.navbar, .navbar-default {
    background-color: #0e4f9f !important;
  }
  
  .navbar-brand{
      color: #FFA103 !important;
  }
  .navbar-default {
    background-image: none;
    border: none;
    border-radius: 0;
  }

  .navbar-nav>.active {
    border-bottom: 5px solid #FFA013 !important;
  }
  
  .navbar-default .navbar-nav > li > a {
    color: white !important;
  }
  
  .navbar-default .navbar-brand {
    color: white;
  }
  
  .navbar-default .navbar-toggle {
    border-color: white;
  }
  
  .navbar-default .navbar-toggle .icon-bar {
    background-color: white;
  }
  
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
  }
  
  .navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
    color: white;
    background-color: transparent;
  }
  
  .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: white;
    background-color: transparent;
  }
  #responsive-navbar-nav
  {
    color: white;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
  }
  .toggleBtn
  {
    background-color:#FFA103 !important;
  }
  .navbar__link--active {
    border-bottom: 3px solid #FFA103;
    transition: border-bottom .5s ease-in-out;
  }
  .lgo
  {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
img
{
  width: 100%;
}
.headings
{
  background: transparent; 
  margin-top: -30%;
}
.headings>h1
{
  color: #FFA103;
  font-size: 4.5rem;
  font-family: Jelle;
}
.headings>h4
{
  color: #FFFFFF;
  font-family: Jelle;
  font-size: 2.5rem;
}
.headings>button{
  margin-top: 10px;
  color: white;
  font-family: Jelle;
  font-size: 1.25rem;
  border-radius: 20px;
  border-color: #FFA103;
  background-color: transparent;
  border-width: 2px;
}
.headings>button:hover{
  border-radius: 20px;
  border-color: white;
  color: #FFA103;
}
.heroBn
{
  margin-bottom: -20px;
  padding-bottom: 0px;
}

@media (max-width: 995px)
{
  .headings
  {
    margin-top: -36%;
  }
}
@media (max-width: 768px)
{
  .headings
  {
    margin-top: -50%;
  }
}
@media (max-width: 509px)
{
  .headings
  {
    margin-top: -50%;
  }
  .headings>h1
  {
    font-size: 2rem;
  }
  .headings>h4
  {
    font-size: 1rem;
  }
  .headings>button
  {
    font-size: 0.75rem;
  }  
}
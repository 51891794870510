.thread>h2
{
    color: #7E7E7E;
    padding: 50px;
    text-align: center;
    margin-bottom: 40px;
}
.carousel-caption>h4
{
    color: #7E7E7E;
}
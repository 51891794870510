h4
{
    color:#FFA103;

}
.footer
{    
    padding: 40px;
}
.socialmedia
{
    color:#FFA103;
    margin-top: 20px;
}
.icons
{
    margin: 8px;
}
a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit !important;
}